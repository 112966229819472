import React from "react";
import styled from "styled-components";

import iconSrc from "../images/404.svg";

import Menu from "../components/menu.js"
import Footer from "../components/footer";

const NotFound = styled.div`
  text-align: center;

  h3 {
    font-weight: normal;
  }
`;

export default function FourOhFour() {
  return (
    <>
      <Menu />
      <NotFound className="hero all-page">
        <div className="container py-12 max-w-[750px] mx-auto px-4">
          <img className="mx-auto" src={iconSrc} alt="404 page" />
          <h1 className="title-page">Страница не найдена</h1>
          <h3>Попробуйте вернуться назад.</h3>
          <a href="/"><button className="button button_primary mt-50 mx-auto">Вернуться на главную</button></a>
        </div>
      </NotFound>
      <Footer />
    </>
  )
}